.HomePage {
	margin: auto;
  	width: 500px;
  	padding: 75px;
}

.Small .HomePage {
	width: 90%;
  	max-width: 300px;
  	padding: 40px;
}

.HomePage .Name {
	text-align: center;
	font-size: 52px;
	font-weight: 500;
	letter-spacing: 10px;
	margin-bottom: 5px;
}

.Small .HomePage .Name {
	font-size: 36px;
	letter-spacing: 3px;
	font-weight: 500px;
}

.HomePage .Contact {
	text-align: center;
	font-size: 13px;
	display: block;
	width: 100%;
}

.Small .HomePage .Contact {
	font-size: 11px;
	letter-spacing: 0.3px;
	text-align: center;
}
.HomePage .Contact a {
	color: inherit;
	text-decoration: none;
}

.HomePage .Contact a:hover {
	color: inherit;
	text-decoration: underline;
}

.HomePage .Divider {
	margin: auto;
	margin-top: 50px;
	height: 50px;
	border-top: 1px solid #EF5F30;
	width: 80%;
}

.HomePage .Bio {
	text-align: distribute;
	width: 98%;
	margin: auto;
	font-weight: 600;
}

.HomePage ul {
	margin-top: 25px;
	padding-left: 23px;
}

.HomePage li {
	margin-bottom: 15px;
}

.HomePage h3 {
	margin-bottom: 0px;
}

.HomePage .ProjectName {
	font-weight: 600;
	color: #913B19;
	text-decoration: none;
}

.HomePage .ProjectName:hover {
	font-weight: 600;
	color: #6E2D13;
}

.HomePage .InactiveProjectName {
	font-weight: 600;
	color: inherit;
}

.HomePage a {
	color: #913B19;;
}

.HomePage a:hover {
	color: #6E2D13;;
}