body {
	margin: 0;
	padding: 0;
	font-family: 'Noto', sans-serif;
	color: #313131;
	-webkit-font-smoothing: antialiased;
  	-moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
	font-family: 'Encode Sans SC', sans-serif;
}