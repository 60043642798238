.Seven {
	padding: 50px;
	padding-top: 30px;
	
}

.Seven .Container {
	max-width: 800px;
	width: 100%;
	margin: auto;
	display: flex;
	flex-wrap: wrap;
}

.Seven .Left {
	min-width: 250px;
	width: 100%;
	max-width: 400px;
	padding-right: 70px;
}
