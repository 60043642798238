.App {
  text-align: left;
}

.App .Medium {
  
  font-size: 16px;
  
}

.App .Small {
  font-size: 14px;
}


.App h3 {
  font-weight: 600;

}
.BackButton {
  color: #E3DBCD;
}

.BackButton:hover {
  color: #59564f;
  cursor: pointer;
}